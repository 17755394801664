// extracted by mini-css-extract-plugin
export var fontFamilyHeader = "sectionLists-module--font-family-header--2xxLY";
export var btn = "sectionLists-module--btn--mIb9W";
export var contentContainer = "sectionLists-module--content-container--2VgTA";
export var Item = "sectionLists-module--Item--2QcEo";
export var ItemTitle = "sectionLists-module--ItemTitle--2sFz_";
export var ItemDetailsContainer = "sectionLists-module--ItemDetailsContainer--CJ_hF";
export var ItemDetailsLabel = "sectionLists-module--ItemDetailsLabel--3xScK";
export var ItemDetailsLink = "sectionLists-module--ItemDetailsLink--2P5J0";
export var PillList = "sectionLists-module--PillList--qgqok";
export var Pill = "sectionLists-module--Pill--2_Fut";